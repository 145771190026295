<template>
  <div class="flex w-full h-full justify-center">
    <div class="fixed w-10/12 ml-auto">
      <ul class="discoverycontainer">
				<div class="discoveryArrow" ref="discoverycontainer">
        <li
          v-for="(item, index) in completeArray"
          @click="setPos(index)"
          :key="index"
          class="discoverylist flex"
          :class="{
            active: checkPos(currentPos, index),
            neighbor: checkPosClose(currentPos, index),
            neighborClose: checkPosNeighborClose(currentPos, index),
          }"
        >
          <div class="relative overflow-hidden h-14 w-16 rounded">
            <div
              class="w-9 h-9 absolute z-30 bg-cover rounded-full mt-2.5 ml-6"
              :style="`background-image: url('https://yt3.googleusercontent.com/${item.thumb}=s88-c-k-c0x00ffffff-no-rj'); outline: 3px solid #F7F7F7;`"
            ></div>
            <div
              class="w-16 h-16 ml-4 absolute z-20"
              style="background: radial-gradient(41.8% 41.8% at 50% 50%, #f7f7f7 0%, rgba(247, 247, 247, 0) 100%), linear-gradient( 270deg, #f7f7f7 57.29%,rgba(247, 247, 247, 0) 100%);
              "
            ></div>
            <div
              class="w-20 h-20 -mt-2 -ml-2 rounded bg-cover absolute"
              :style="`background-image: url('https://yt3.googleusercontent.com/${item.thumb}=s88-c-k-c0x00ffffff-no-rj'); filter: blur(5px);
`"
            ></div>
          </div>

          <div class="ml-6 title">{{ item.title }}</div>
          <!-- {{ item }} -->
        </li>
			</div>
      </ul>
    </div>
  </div>
</template>

<script>
import SweetScroll from 'sweet-scroll';

export default {
  name: "App",
  data() {
    return {
      currentPos: 0,
      completeArray: [1, 2, 3],
			scrollObj: {}
    };
  },
  methods: {
		setPos(index) {
			this.currentPos = index;
			this.scrollTo(this.currentPos * 60, 300)
		},
    checkPos(pos, n) {
      if (pos === n) {
        return true;
      } else {
        return false;
      }
    },
    checkPosClose(pos, n) {
      if (pos - 1 === n || pos + 1 === n) {
        return true;
      }

      return false;
    },

    checkPosNeighborClose(pos, n) {
      if (pos - 2 === n || pos + 2 === n) {
        return true;
      }

      return false;
    },
    async grab() {
      console.log(this);
      await fetch(
        "https://raditube-discovery-bucket.s3.us-west-1.amazonaws.com/6YWmk6L_summary.json"
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.completeArray = data.bestRatio;
          return data.bestRatio;
          // console.log(data);  // { "userId": 1, "id": 1, "title": "...", "body": "..." }
        });
    },
  },
  mounted() {

    this.scrollObj = SweetScroll.create({
      header: null,
			easing: 'easeOutExpo',
			duration: 1000,
    }, '#discoverycontainer');
    // document.addEventListener('keydown', function() {console.log("test")});

    document.addEventListener("keydown", (e) => {
      e.preventDefault();
      if (e.key === "ArrowDown") {
        this.currentPos++;
				this.$refs.discoverycontainer.style.top = `-${this.currentPos*63}px`;
				// this.scrollObj.to(this.currentPos * 63);

      }
      if (e.key === "ArrowUp") {
        this.currentPos >= 1 ? this.currentPos-- : null;
				this.$refs.discoverycontainer.style.top = `-${this.currentPos*63}px`;

				// this.scrollObj.to(this.currentPos * 63);
      }
      if (e.key === "ArrowRight") {
        window.open(
          `https://www.youtube.com/channel/${
            this.completeArray[this.currentPos].id
          }`,
          "_blank"
        );
      }
      e.preventDefault();
    });

    const data = this.grab();
    this.completeArray = data;

    console.log(this);
    // this.grab()

    // document.addEventListener("keydown", this.up())
  },
};
</script>

<style>
.active {
  font-size: 20px;
  width: 98%;
  border: 2px solid #e9cce1 !important;
  transition: width 0.1s ease-in-out, transform 0.3s ease;
  /* transform: scale(105%) */
}

.neighbor {
  width: 95%;
}

.active .title {
	margin-left: 3.2rem;
  transition: margin 0.1s ease-in-out;

}

.neighbor .title {
	margin-left: 2.2rem;
  transition: margin 0.1s ease-in-out;

}

.neighborClose .title {
	margin-left: 1.8rem;
  transition: margin 0.1s ease-in-out;

}

.neighborClose {
  width: 94%;
}

.discoverycontainer {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: center;
  height: 100vh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
}

.discoveryArrow {
	list-style-type: none;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: center;
	width: 90%;
  height: 100vh;
	position: relative;
	transition: top 0.4s ease-in-out;

}

.discoverylist {
  min-width: 93%;
  background: rgb(247, 247, 247);
  margin-top: 10px;
  border-radius: 5px;
  line-height: 60px;
  max-height: 60px;
  height: 60px;
  transition: width 0.2s ease-in-out;
  scroll-snap-align: start;
  border: 1px solid rgb(214, 214, 214);
}
</style>
